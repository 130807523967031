import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from './router'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'
/* eslint-disable no-param-reassign */
export default new Vuex.Store({
  state: {
    errorMessage: null,
    lang: 'zh',
    auth: {
      authorized: false, // true false
      userRole: '', // admin hr superviser student teacher
      reserveMode: true,
      user: null
    },
    // status
    isLoading: false,
    isLogining: false,
    classType: 'talk',
    unread_count: 0,
    nodeEnv: process.env.NODE_ENV
  },

  getters: {
    user: state => state.auth.user,
    authorized: state => state.auth.authorized,
    userRole: state => state.auth.userRole,
    reserveMode: state => state.auth.reserveMode,
    lang: state => state.lang,
    isLoading: state => state.isLoading,
    classType: state => state.classType,
    isLogining: state => state.isLogining,
    unread_count: state => state.unread_count,
    nodeEnv: state => state.nodeEnv,
  },

  actions: {
    isLoading: (context, data) => {
      context.commit('ISLOADING', data)
    },

    isLogining: (context, data) => {
      context.commit('ISLOGIN', data)
    },

    setRole: (context, data) => {
      context.commit('SETROLE', data)
    },

    // 設定語言
    setLang: ({ commit }, data) => {
      commit('SETLANG', data)
    },

    // 主任模式
    setMode: ({ commit }, data) => {
      commit('SETMODE', data)
    },

    // register: async ({ dispatch }, user) => {
    //   dispatch('isLoading', true)
    //   await axios.post('/register', {
    //     'name': user.name,
    //     'password': user.password,
    //     'password_confirmation': user.password_confirmation,
    //     'email': user.email,
    //     'name_en': user.name_en,
    //     'country_code': user.country_code,
    //     'mobile': user.mobile,
    //     'supervisor': user.supervisor
    //   })
    //   .then(() => {
    //     dispatch('isLoading', false)
    //     router.push({ path: '/my_courses' })
    //     dispatch('login', { account: user.email, password: user.password })
    //   })
    //   .catch((err) => {
    //     window.alert(err.response.data.message)
    //     dispatch('isLoading', false)
    //   })
    // },

    login: async ({ commit, dispatch }, user) => {
      await axios.post('/login', {
        'email': user.account,
        'password': user.password
      })
      .then(response => {
        commit('LOGIN', response.data)
        localStorage.setItem('authorizedToken', response.data.token)
        dispatch('getProfile')
        // VueCookie.set('token', response.data.token)
      })
      .catch(() => {
        // if (error.response.status === 401) {
          // dispatch('updateAlert', { isAlert: true, isHead: true, alertMsg: '帳號密碼錯誤', type: 'alert' })
        // }
      })
    },

    logout: async ({ commit }) => {
      commit('LOGOUT')
      await axios.post('/logout')
        .then(() => {
          dispatch('isLogining', false)
          localStorage.removeItem('authorizedToken')
          commit('LOGOUT')
          dispatch('setLang', 'zh')
        }).catch(() => {
          commit('LOGOUT')
        })
    },

    getProfile: async ({ commit, dispatch }) => {
      let { data } = await axios.get('/users/me')
      if (data && data.error) {
        dispatch('logout')
      } else {
        commit('UPDATEPROFILE', data)
      }
    },

    refreshToken: async () => {
      let { data } = await axios.get('/refresh_token')
      localStorage.setItem('authorizedToken', data.access_token)
      // VueCookie.set('authorizedToken', data.access_token)
      window.location.reload()
    },

    setErrorMessage (state, message) {
      state.errorMessage = message
    },

    setClassType ({ commit }, type) {
      commit('UPDATETYPE', type)
    },

    unread_count: ({ commit }) => {
      axios.get('/news/unread_count')
      .then(response => {
        commit('UNREADCOUNT', response.data)
      })
      .catch(() => {
      })
    },
  },
  mutations: {
    UPDATETYPE (state, data) {
      state.classType = data
    },
    ISLOADING (state, data) {
      state.isLoading = data
    },
    ISLOGIN (state, data) {
      state.isLogining = data
    },
    SETROLE (state, data) {
      state.auth.userRole = data
    },
    SETLANG (state, value) {
      state.lang = value
      Vue.config.lang = state.lang
    },
    SETMODE (state, data) {
      state.auth.reserveMode = data
    },

    LOGIN (state) {
      state.auth.authorized = true
    },
    LOGOUT (state) {
      state.auth.authorized = false
      state.auth.user = {}
      state.auth.userRole = ''
      // VueCookie.delete('token')
    },
    UPDATEPROFILE (state, data) {
      state.auth.userRole = data.role
      state.auth.user = data
    },
    UNREADCOUNT (state, data) {
      state.unread_count = data.count
    }
  },
  strict: debug,
  plugins: [createPersistedState({
    paths: [
      'auth'
    ]
  })]
})